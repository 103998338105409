import React from 'react'

import hero_image from '../assets/images/hero_image.svg'
import hero_circle_small from '../assets/images/hero_circle_small.svg'
import hero_circle_large from '../assets/images/hero_circle_large.svg'

import styles from '../styles/components/Hero.module.css'

const Hero = ({left, right}) => {
    return (
        <>
            <div className={styles.hero}>
                <div className={styles.container}>

                    <div className={styles.circleContainerLarge}>
                        <img src={hero_circle_large} className={styles.imageWidth} alt='Large Circle' />
                    </div>

                    <div className={styles.circleContainerSmall}>
                        <img src={hero_circle_small} className={styles.imageWidth} alt='Small Circle' />
                    </div>

                    <div className={styles.headerMobileContainer}>
                        <h2 className={styles.headerMobile}>
                            - Universal IoT Connectivity
                        </h2>
                    </div>

                    <div className={styles.imageContainer}>
                        <img src={hero_image} className={styles.imageWidth} alt='Laptop and Mobile Phone Illustration' />
                    </div>

                    <div className={styles.textContainer}>
                        <h2 className={styles.header}>
                            - Universal IoT Connectivity
                        </h2>

                        <h1 className={styles.title}>
                            Customised Client Solutions
                        </h1>

                        <p className={styles.description}>
                            Ubitech is an innovative technology company focusing on  
                            wireless communications and specialised medical software
                        </p>
                    </div>

                    <div className={styles.buttonContainer}>
                        <div className={styles.buttonLeft}>
                            {left}
                        </div>
                        <div className={styles.buttonRight}>
                            {right}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero
