import React from 'react'

import styles from '../styles/components/Card.module.css'

const Card = (props) => {
    return (
        <>
            <div className={styles.card} style={ props.image }>
                <div className={styles.container}>
                    <h2 className={styles.title}>
                        {props.title}
                    </h2>

                    <p className={styles.description}>
                        {props.description}
                    </p>
                    
                    <div className={styles.button}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Card
