import React from 'react';

import Meta from './Meta';
import Nav from './Nav';
import Footer from './Footer';

const Layout = (props) => {
    return (
        <>
            <Meta />
            <Nav />
                <main>
                    {props.children}
                </main>
            <Footer />
        </>
    )
}

export default Layout;