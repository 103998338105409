import React from 'react'
import PropTypes from 'prop-types'
import styles from '../styles/components/Button.module.css'


/*

Added React.forwardRef to Button and created second component called ButtonNoRef

Go through and check all buttons in project and update them all to the new way

*/

// Button should be used inside Link component
export const Button = React.forwardRef(({onClick, href, text, buttonstyle, buttonsize}, ref) => {
    return (
        <a
            onClick={onClick}
            href={href}
            className={`${styles.button} ${buttonstyle} ${buttonsize}`}
            ref={ref}
        >
            {text}
        </a>
    )
});

Button.displayName = 'Button';

Button.defaultProps = {
    buttonstyle: `${styles.button} ${styles.buttonStyle1}`,
    buttonsize: styles.buttonSizeMedium,
    text: 'Button',
}

Button.propTypes = {
    buttonstyle: PropTypes.string,
    buttonsize: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
}


// ButtonNoRef should NOT be used inside Link component
export const ButtonNoRef = ({onClick, text, buttonstyle, buttonsize}) => {
    return (
        <p
            onClick={onClick}
            className={`${styles.button} ${buttonstyle} ${buttonsize}`}
        >
            {text}
        </p>
    )
}

ButtonNoRef.defaultProps = {
    buttonstyle: `${styles.button} ${styles.buttonStyle1}`,
    buttonsize: styles.buttonSizeMedium,
    text: 'Button',
}

ButtonNoRef.propTypes = {
    buttonstyle: PropTypes.string,
    buttonsize: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
}
