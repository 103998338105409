import React from 'react'
import PropTypes from 'prop-types'
import styles from '../styles/components/SectionHorizontal.module.css'

const SectionHorizontal = (props) => {
    return (
        <div className={styles.section}>
            <div className={styles.container}>
                <h1 className={styles.title}>
                    {props.title}
                </h1>
                <p className={styles.description}>
                    {props.description}
                </p>
                <div className={styles.button}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

SectionHorizontal.defaultProps = {
    title: 'Title',
    description: 'This is the description.',
}

SectionHorizontal.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default SectionHorizontal
