import React, { useState, useCallback, useEffect } from 'react'
import {Router} from 'react-router-dom'
import { ButtonNoRef } from '../components/Button'
import Axios from "axios"
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

import styles from '../styles/components/ContactForm.module.css'
import buttonStyles from '../styles/components/Button.module.css'

const ContactVerifyForm = () => {

    const [token, setToken] = useState(null);
    const [feedback, setFeedback] = useState(false);
    const [response, setResponse] = useState(null);
    const [nameValue, setNameValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [messageValue, setMessageValue] = useState("");
    const subjectValue = "Ubitech Contact Form";
    const { executeRecaptcha } = useGoogleReCaptcha();
    var validator = require("email-validator");


    const mailerAction = async (name, email, subject, message, callback) => {
        
        if (name !== "" &&
            validator.validate(email) &&
            message !== ""
        ) {
            const body = {
            Name: name,
            Email: email,
            Subject: subject,
            Message: message,
            token: token,
            };
            const url = 'https://api.ubitheraplay.com/api/mailer/contact';
            try {
                await Axios.post(url, body).then((result) => {
                    if (result) {
                        callback(result.data.message, result.status);
                        alert('Message sent successfully');
                        //window.location.reload();
                        Router.push('/');
                    } else {
                        callback("No email sent", 500);
                        console.warn("No email sent");
                    }
                });
            }
            catch (e) {
                console.error(e);
            }
        }
        else {
            setFeedback(true);
        }
    };

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        
        if (!executeRecaptcha) {
            //console.log('Execute recaptcha not yet available');
            return;
        }
        
        const _token = await executeRecaptcha('onClick');
        setToken(_token);
        //console.log(_token);
    }, [executeRecaptcha]);
  
    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    const submitMessage = () => {
        handleReCaptchaVerify();

        mailerAction(
            nameValue, 
            emailValue, 
            subjectValue, 
            messageValue, 
            (msg, status) => { 
                setResponse( { msg, status } );
                console.log (response.status);
            }
        );
    }
    
    return (
        <div className={styles.formSection}>

            <h1 className={styles.formTitle}>
                Contact Form
            </h1>

            <div className={styles.formContainer}>
                <div className={styles.formName}>
                    <input 
                        className={styles.formInput} 
                        type='text' 
                        name='name' 
                        value={nameValue} 
                        onChange={(e) => setNameValue(e.target.value)} 
                        placeholder='Name' 
                    />
                </div>
                <div className={styles.formEmail}>
                    <input 
                        className={styles.formInput} 
                        type='email' 
                        name='email' 
                        value={emailValue}
                        onChange={(e) => setEmailValue(e.target.value)} 
                        placeholder='Email' 
                    />
                </div>
                <div className={styles.formMessage}>
                    <textarea 
                        className={styles.formInputTextArea} 
                        name='message' 
                        rows='4'
                        value={messageValue}
                        onChange={(e) => setMessageValue(e.target.value)} 
                        placeholder='Message' 
                    />
                </div>
                
                <div className={styles.formCheck}>
                    <p className={feedback ? styles.formCheckMessage : styles.formCheckMessageHidden}>
                        * Please fill out all fields correctly
                    </p>
                </div>
                
                <div className={styles.formButton}>
                    <ButtonNoRef 
                        onClick={submitMessage}
                        buttonstyle={buttonStyles.buttonStyle1}
                        buttonsize={buttonStyles.buttonSizeMedium}
                        text='Submit Message'
                    />
                </div>
            </div>
        </div>
    )

}

const ContactForm = () => {
    const sitekey = "6LdcpbcUAAAAAGLQU6Pg8xUCYjaFF4bgqizaxu25"
    
    return (
        <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
 
            <ContactVerifyForm />
  
        </GoogleReCaptchaProvider>
    )
}

export default ContactForm
