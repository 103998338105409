import React from 'react'
import SectionHorizontal from './SectionHorizontal'

const SectionContactUs = (props) => {
    return (
        <div>
            <SectionHorizontal
                title='Get In Contact Today'
                description='Ubitech offers consultancy services in these areas, typically leading to bespoke 
                    customer solutions based on our technologies. Additionally we have participated 
                    in consortium projects bringing together technical expertise from multiple teams 
                    to achieve a multi-disciplinary approach to solution development. More detailed 
                    information on our team and our solutions can be obtained by contacting us.'
            >
                {props.children}
            </SectionHorizontal>
        </div>
    )
}

export default SectionContactUs
