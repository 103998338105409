import React, { useEffect } from 'react'
import {Link, useLocation} from 'react-router-dom'
import Meta from '../components/Meta'
import HeroSecondary from '../components/HeroSecondary'
import { ButtonNoRef } from '../components/Button'
import SectionLarge from '../components/SectionLarge'
import SectionContactUs from '../components/SectionContactUs'
import paths from '../constants/paths'

import section_overview from '../assets/images/section_overview.svg'
import section_communications from '../assets/images/section_communications.svg'
import section_distribution from '../assets/images/section_distribution.svg'

import styles from '../styles/pages/Communications.module.css'
import buttonStyles from '../styles/components/Button.module.css'


const scroll = () => {
    if (typeof window !== 'undefined') {

        var id = 'pageTop';
        var yOffset;
        var element = document.getElementById(id);
        let y;
        if (window.scrollY === 0) {
            yOffset = -50;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }
        else {
            yOffset = -10;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }

        window.scrollTo({top: y, behavior: 'smooth'});
    }
}

const Comms = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Meta
                title='Ubitech | Wireless Communications'
            />

            <section className={styles.heroSection}>
                <HeroSecondary
                    title='Wireless Communications'
                    description='Experts in mobile app connectivity development and standardisation of wireless systems'
                >
                    <ButtonNoRef
                        onClick={() => {scroll()}}
                        buttonstyle={buttonStyles.buttonStyle1}
                        buttonsize={buttonStyles.buttonSizeMedium}
                        text='Learn More'
                    />
                </HeroSecondary>
            </section>

            <div className={styles.page} id='pageTop'>
                <div className={styles.pageContainer}>

                    <section className={styles.section1}>
                        <SectionLarge
                            imageOnRight={false}
                            image={section_overview}
                            alt='section overview'
                            title='Standardisation'
                            description="Working with the Wireless World Research Forum (WWRF) Informal Experts Group (IEG) to provide standardisation advice to the International Telecommunications Union (ITU) WP5D on new technologies. 
                                The first report generated by the WWRF IEG can be downloaded here: EUHT EVALUATION REPORT"
                            hasExtraContent={true}
                            extraContent='Ubitech is a member of the Wireless World Research Forum Informal Experts Group (WWRF IEG) to provide standardisation advice into the International Telecommunications Union (ITU).
                                The WWRF IEG and Ubitech present and support the advice on the ITU-R plenary meetings in Geneva, Switzerland. The work is ongoing and the second evaluation report will be released by the end of October, 2021 (Second report on EUHT Evaluation).'
                        />     
                    </section>
                    <div className={styles.seperationContainer}>
                        <div className={styles.seperation} />
                    </div>
                    <section className={styles.section2}>
                        <SectionLarge
                            imageOnRight={true}
                            image={section_communications}
                            alt='section communications'
                            title='UbiNet'
                            description="Our UbiNet technology is a software platform that can enable communications among heterogeneous smart devices 
                                on an anywhere, anytime, anything basis. The platform allows for communication amongst users without the need for a network
                                provider or access point, allowing for vehicle to vehicle, military, and emergency applications."
                            hasExtraContent={true}
                            extraContent='The technology is a bespoke ubiquitous network system, consisting of IP-based smart devices 
                                and is independent of any communication infrastructure. Ubitech Ltd has defined this network system, 
                                in order to support reliability and interoperability among current wireless technologies as well as 
                                future investments in similar smart IP-based devices. The UbiNet technology was originally funded by 
                                Innovate UK (TSB) and by the European FP7 programme under the name of ICT RESCUE'
                        />
                        <div className={styles.videoContainer1}>
                            <div className={styles.videoContainer2}>
                                <iframe className={styles.iFrame}
                                    title="Ubitech Ltd"
                                    src="https://player.vimeo.com/video/46635951" 
                                    frameBorder="0" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                        <div className={styles.rescueContainer}>
                            <p className={styles.rescueContent}>
                                RESCUE’s vision was the research and development of a theoretical, technological, and practical basis for a 
                                novel communications technology that will efficiently utilise the characteristics of future wireless networks, 
                                which are expected to be dense in terms of node and link populations, and heterogeneous in terms of their 
                                built-in capabilities. RESCUE’s Links-on-the-fly technology proposed a novel concept for robust, efficient, 
                                and smart information transmission in unpredictable environments which are characterised by substantial and 
                                sudden changes of the network’s structure.
                            </p>    
                        </div>           
                    </section>
                    <div className={styles.seperationContainer}>
                        <div className={styles.seperation} />
                    </div>
                    <section className={styles.section3}>
                        <SectionLarge
                            imageOnRight={false}
                            image={section_distribution}
                            alt='section distribution'
                            title='UbiMessenger'
                            description="UbiMessenger is a simple to use text and voice messaging application that works anywhere. A smart device running UbiMessenger 
                                can communicate by voice or instant messaging directly with another device within Wi-Fi range without the need for any network 
                                infrastructure. UbiMessenger searches for, discovers and lists all devices in your close proximity, which are UbiMessenger enabled and 
                                which are in range allowing you to select and talk to or text message any other device. The application allows people to connect and 
                                share information based on peer-to-peer local communication and is based on the Wi-Fi Direct functionalities provided by Google."
                            hasExtraContent={true}
                            extraContent='Once downloaded, UbiMessenger does not require network or server access. During UbiMessenger operation, the use of all cellular 
                                and other device services are maintained and can be accessed by the user. '
                        />       
                    </section>
                    <div className={styles.seperationContainer}>
                        <div className={styles.seperation} />
                    </div>
                    <section className={styles.contactSection}>
                        <SectionContactUs>
                            <Link to={paths.contact}>
                                <ButtonNoRef 
                                    buttonstyle={buttonStyles.buttonStyle1}
                                    buttonsize={buttonStyles.buttonSizeMedium}
                                    text='Contact Us'
                                />
                            </Link>
                        </SectionContactUs>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Comms;
