import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Meta from '../components/Meta'
import HeroSecondary from '../components/HeroSecondary'
import { ButtonNoRef } from '../components/Button'
import SectionLarge from '../components/SectionLarge'
import SectionContactUs from '../components/SectionContactUs'

import paths from '../constants/paths'

import section_communications from '../assets/images/section_communications.svg'
import section_software from '../assets/images/section_software.svg'

import styles from '../styles/pages/About.module.css'
import buttonStyles from '../styles/components/Button.module.css'


const scroll = () => {
    if (typeof window !== 'undefined') {

        var id = 'pageTop';
        var yOffset;
        var element = document.getElementById(id);
        let y;
        if (window.scrollY === 0) {
            yOffset = -50;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }
        else {
            yOffset = -10;
            y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        }

        window.scrollTo({top: y, behavior: 'smooth'});
    }
}

const About = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <>
            <Meta
                title='Ubitech | About'
            />

            <section className={styles.heroSection}>
                <HeroSecondary
                    title='About Ubitech'
                    description='Who we are and what we do'
                >
                    <ButtonNoRef
                        onClick={() => {scroll()}}
                        buttonstyle={buttonStyles.buttonStyle1}
                        buttonsize={buttonStyles.buttonSizeMedium}
                        text='Learn More'
                    />
                </HeroSecondary>
            </section>

            <div className={styles.page} id='pageTop'>
                <div className={styles.pageContainer}>
                    
                    <section className={styles.introSection}>
                        <div className={styles.introSectionContainer}>
                            <h1 className={styles.introSectionTitle}>
                                How Ubitech Started
                            </h1>
                            <p className={styles.introSectionParagraph}>
                                Ubitech Ltd was formed in 2012 by a group of engineers and software developers 
                                seeking to exploit the commercial potential of their academic research and 
                                development in wireless and online service solutions. Our staff are qualified, 
                                experienced software developers who have achieved recognition in their chosen 
                                fields. We work with clients to understand and define their requirements and 
                                work closely with the client or technology partner during the project. We aim 
                                to deliver a customised solution which meets client requirements but which 
                                also builds on our previous work in these areas to provide a cost effective, 
                                fast to market solution. For clients with their own development teams we provide 
                                technical consultancy services and can provide an advisory role throughout the 
                                client project.
                            </p>
                        </div>
                    </section>

                    <section className={styles.bannerSection}>
                        <div className={styles.bannerSectionTitleContainer}>
                            <h1 className={styles.bannerSectionTitle}>
                                Cutting Edge Wireless Communication Technology and Software Solutions
                            </h1>
                        </div>
                    </section>

                    <section className={styles.marketSection}>
                        <div className={styles.marketSectionContainer}>
                            <div className={styles.marketSection1}>      
                                <SectionLarge
                                    imageOnRight={true}
                                    image={section_software}
                                    alt='Image'
                                    title='Our Healthcare Software'
                                    description='Our online services platform development provides a distribution platform for specialised 
                                        services, notably in healthcare. Working with specialist game developers and healthcare 
                                        professionals, our online platform Therapy Reloaded is providing therapeutic patient services for children with 
                                        motor impairment. In addition to delivering the gaming services, the platform enables the patient’s 
                                        healthcare professional to derive performance metrics and data to better understand the patient’s 
                                        condition and the appropriate ongoing treatment.'
                                    hasExtraContent={true}
                                    extraContent='The Therapy Reloaded suite provides tailor-made video games for the therapeutic support of 
                                        children with motor impairment. The target markets are specialised schools and clinics supporting the 
                                        therapeutic care of mobility impaired children of 5-18 years with motor impairment.'
                                >
                                    <a href='https://ubitheraplay.com/' target='_blank' rel='noopener noreferrer'>
                                        <ButtonNoRef 
                                            buttonstyle={buttonStyles.buttonStyle1}
                                            buttonsize={buttonStyles.buttonSizeMedium}
                                            text='Therapy Reloaded'
                                        />
                                    </a>
                                </SectionLarge>
                                
                            </div>

                            <div className={styles.seperationContainer}>
                                <div className={styles.seperation} />
                            </div>

                            <div className={styles.marketSection2}>
                                <SectionLarge
                                    imageOnRight={true}
                                    image={section_communications}
                                    alt='Image'
                                    title='Our Communication Technologies'
                                    description='The first solution was UbiNet, a software platform to enable wireless communications 
                                        among multiple heterogeneous smart wireless devices. UbiNet employs ad-hoc peer to peer networking 
                                        technology, which enables devices to communicate without the need for a fixed wireless network or 
                                        infrastructure. Our innovative technology provides self-organised wireless device communication 
                                        protocols with the facility of cognition. The ubiquitous services are intelligent, autonomous and 
                                        adaptive to ambient conditions in order to provide users with high quality communication. Ubitech’s 
                                        technology was deployed in the EU RESCUE project aimed at providing seamless emergency communications 
                                        amongst multiple radio technologies.'
                                    hasExtraContent={true}
                                    extraContent='The evolving 5G technologies will deploy peer to peer networking to enable much larger 
                                        volumes of data traffic than can be handled exclusively over fixed networks. This is already deployed 
                                        in Internet of Things (IoT) solutions and in emerging solutions such as driverless vehicles.'
                                >
                                    <Link to={paths.communications}>
                                        <ButtonNoRef 
                                            buttonstyle={buttonStyles.buttonStyle1}
                                            buttonsize={buttonStyles.buttonSizeMedium}
                                            text='Communications'
                                        />
                                    </Link>
                                </SectionLarge> 
                            </div>

                            <div className={styles.seperationContainer}>
                                <div className={styles.seperation} />
                            </div>
                        </div>
                    
                    </section>

                    <section className={styles.contactSection}>
                        <SectionContactUs>
                            <Link to={paths.contact}>
                                <ButtonNoRef 
                                    buttonstyle={buttonStyles.buttonStyle1}
                                    buttonsize={buttonStyles.buttonSizeMedium}
                                    text='Contact Us'
                                />
                            </Link>
                        </SectionContactUs>
                    </section> 
                
                </div>
            </div> 
        </>
    )
}

export default About
