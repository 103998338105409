import React from 'react'
import PropTypes from 'prop-types'
import styles from '../styles/components/SectionLarge.module.css'

import hero_circle_small from '../assets/images/hero_circle_small.svg'

const SectionLarge = (props) => {
    return (
        <div className={styles.section}>

            <div className={styles.container}>

                <div className={props.imageOnRight ? styles.imageContainer1 : styles.imageContainer2}>
                    <img className={styles.imageWidth} src={props.image} alt={props.alt} />
                </div>

                <div className={props.imageOnRight ? styles.textContainer1 : styles.textContainer2}>

                    <h1 className={styles.title}>
                        {props.title}
                    </h1>

                    <p className={styles.description}>
                        {props.description}
                    </p>

                </div>
            </div>

            <div className={props.hasExtraContent ? styles.contentContainer : styles.hidden}>
                <p className={styles.content}>
                    {props.extraContent}
                </p>
            </div>

            <div className={props.children ? styles.button : styles.hidden}>
                {props.children}
            </div>

        </div>
    )
}

SectionLarge.defaultProps = {
    imageOnRight: true,
    image: hero_circle_small,
    alt: 'Image',
    title: 'Title',
    description: 'This is the description.',
    extraContent: 'This is the extra content area.',
    hasExtraContent: false,
}

SectionLarge.propTypes = {
    imageOnRight: PropTypes.bool,
    image: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    extraContent: PropTypes.string,
    hasExtraContent: PropTypes.bool,
}

export default SectionLarge
