import React from 'react'
import PropTypes from 'prop-types'

import hero_circle_small from '../assets/images/hero_circle_small.svg'
import hero_circle_large from '../assets/images/hero_circle_large.svg'

import styles from '../styles/components/HeroSecondary.module.css'

const HeroSecondary = (props) => {
    return (
        <>
            <div className={styles.hero}>
                <div className={styles.container}>

                    <div className={styles.circleContainerLarge}>
                        <img src={hero_circle_large} alt='Large Circle' />
                    </div>

                    <div className={styles.circleContainerSmall}>
                        <img className={styles.imageWidth} src={hero_circle_small} alt='Small Circle' />
                    </div>

                    <div className={styles.textContainer}>
                        <h1 className={styles.title}>
                            {props.title}
                        </h1>

                        <p className={styles.description}>
                            {props.description}
                        </p>

                        <div className={styles.button}>
                            {props.children}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

HeroSecondary.defaultProps = {
    title: 'Title',
    description: 'This is the description.',
}

HeroSecondary.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default HeroSecondary
