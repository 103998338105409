import React from 'react'
import { Helmet } from "react-helmet";

const Meta = ({ title, keywords, description }) => {
    return (
        <Helmet>
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <meta name='keywords' content={keywords} />
            <meta name='description' content={description} />
            <meta charSet='utf-8' />
            <meta name="theme-color" content="#1480E3" />
            <link rel='icon' href='/favicon.ico' />
            <title>{title}</title>

            <link rel="preload" href="/fonts/Poppins/Poppins-ExtraLight.ttf" as="font" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/Poppins/Poppins-Light.ttf" as="font" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/Poppins/Poppins-Regular.ttf" as="font" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/Poppins/Poppins-Medium.ttf" as="font" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/Poppins/Poppins-SemiBold.ttf" as="font" crossOrigin="anonymous" />
            <link rel="preload" href="/fonts/Poppins/Poppins-Bold.ttf" as="font" crossOrigin="anonymous" />
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Ubitech',
    keywords: 'Ubitech, UbiTheraPlay, Customised Client Solutions, Innovative Technology, Wireless Communications, Specialised Medical Software, Gaming Software, Medical Gaming, Software Distribution, Customer Solutions, Consortium Projects, Technical Expertise, Solution Development, Healthcare Software, Medical Gaming Platform, Neurological Disorders, UbiNet, UbiMessenger, 5G, Internet of Things, Peer to Peer, Standardisation, Wireless World Research Forum, Informal Experts Group, International Telecommunications Union, Smart Device, IP, Innovate UK, European FP7 Programme, ICT RESCUE, Wi-Fi, Cellular, Kingston, Surrey',
    description: 'Ubitech is an innovative technology company focusing on wireless communications and specialised medical software',
}

export default Meta
